/**
 * @generated SignedSource<<626b2a6ae6e69d92350a22760b32f510>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Status = "complete" | "presigned" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UploadsScreen_UploadFragment$data = {
  readonly __typename: "File";
  readonly filename: string;
  readonly id: string;
  readonly status: Status;
  readonly " $fragmentType": "UploadsScreen_UploadFragment";
} | {
  readonly __typename: "Image";
  readonly filename: string;
  readonly id: string;
  readonly status: Status;
  readonly " $fragmentType": "UploadsScreen_UploadFragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "UploadsScreen_UploadFragment";
};
export type UploadsScreen_UploadFragment$key = {
  readonly " $data"?: UploadsScreen_UploadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadsScreen_UploadFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "filename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadsScreen_UploadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Image",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "File",
      "abstractKey": null
    }
  ],
  "type": "Upload",
  "abstractKey": "__isUpload"
};
})();

(node as any).hash = "ea7290bf0f4240d4b5425311a7f0e1ba";

export default node;
