import { useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { LoadingScreen } from "../screens/LoadingScreen";
import { ChildrenProps } from "../types";
import { createRelayEnvironment } from "../lib/createRelayEnvironment";
import config from "../config";
import { useAuthentication } from "../hooks/useAuthentication";

export const CloudProvider = (props: ChildrenProps) => {
  const { secureToken } = useAuthentication();

  const environment = useMemo(() => (
    createRelayEnvironment({ graphqlUrl: config.cloudGraphqlUrl, secureToken, onNotAuthed: () => {
      throw new Error("handle logout")
    }})
  ), [secureToken]);

  if (environment == null) {
    return <LoadingScreen message="Loading environment" />
  }

  return (
    <RelayEnvironmentProvider environment={environment}>
      {props.children}
    </RelayEnvironmentProvider>
  )
}
