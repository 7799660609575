import { useState } from "react";
import { Flex } from "./components/Flex";
import { Link } from "./components/Link";
import { Text } from "./components/Text";
import { useAuthentication } from "./hooks/useAuthentication";
import { useDialog } from "./hooks/useDialog";
import { UploadsScreen } from "./screens/UploadsScreen";
import { DashboardScreen } from "./screens/DashboardScreen";
import { AccountsScreen } from "./screens/AccountsScreen";

export const App = () => {
  const { currentUser, logout } = useAuthentication();
  const dialog = useDialog();
  const [currentPage, setCurrentPage] = useState<"dashboard" | "accounts" | "uploads">("dashboard");

  return (
    <Flex grow={1} row style={{ height: "100vh", overflow: "hidden" }}>
      <Flex justifyContent="space-between" gap={30} style={{ width: 240 }}>
        <Flex style={{ paddingTop: 40 }}>
          <Link title="Dashboard" style={{ padding: `10px 20px` }} onClick={() => setCurrentPage("dashboard")}>Dashboard</Link>
          <Link title="Accounts" style={{ padding: `10px 20px` }} onClick={() => setCurrentPage("accounts")}>Accounts</Link>
          <Link title="Uploads" style={{ padding: `10px 20px` }} onClick={() => setCurrentPage("uploads")}>Uploads</Link>
        </Flex>
        <Flex style={{ padding: 20 }}>
          <Text bold>{currentUser.email}</Text>
          <Link
            href="#"
            title="Logout"
            small
            onClick={(ev) => {
              ev.preventDefault();
              dialog.confirm({
                title: "Log out",
                body: "Are you sure you want to logout?",
                onConfirm: () => logout()
              })
            }}
          >
            Logout
          </Link>
        </Flex>
      </Flex>
      <div style={{ overflowY: "auto", width: "calc(100vw - 240px)", borderLeft: "1px solid lightgray" }}>
        {currentPage === "dashboard" ? (
          <DashboardScreen />
        ) : currentPage === "accounts" ? (
          <AccountsScreen />
        ) : currentPage === "uploads" ? (
          <UploadsScreen />
        ) : null}
      </div>
    </Flex>
  );
}
