import { HTMLAttributes } from "react";

type TextProps = HTMLAttributes<HTMLParagraphElement> & {
  bold?: boolean;
  small?: boolean;
  subdued?: boolean;
};

export const Text = ({ style, bold, small, subdued, ...props }: TextProps) => (
  <p
    {...props}
    style={{
      fontWeight: bold ? "bold" : "normal",
      fontSize: small ? 13 : 16,
      color: subdued ? "rgba(0, 0, 0, 0.6)" : "black",
      ...style
    }}
  />
)
