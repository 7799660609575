import { Flex } from "../components/Flex"
import { Text } from "../components/Text"
import { Loader } from "../components/Loader"

export const LoadingScreen = (props: { message: string }) => (
  <Flex row alignItems="center" justifyContent="center" grow={1}>
    <Flex alignItems="center" justifyContent="center" style={{ background: "rgba(0, 0, 0, 0.08)", height: 15, padding: "20px 25px" }}>
      <Loader />
    </Flex>
    <Flex style={{ background: "rgba(0, 0, 0, 0.04)", height: 15, padding: "20px 25px" }}>
      <Text subdued small>{props.message}</Text>
    </Flex>
  </Flex>
)
