import { AnchorHTMLAttributes } from "react";

type LinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "title"> & {
  title: string;
  bold?: boolean;
  small?: boolean;
};

export const Link = ({ title, style, bold, small, ...props }: LinkProps) => (
  <a
    {...props}
    title={title}
    style={{
      fontWeight: bold ? "bold" : "normal",
      fontSize: small ? 13 : 16,
      color: "dodgerblue",
      cursor: "pointer",
      textDecoration: "none",
      ...style
    }}
  />
)
