/**
 * @generated SignedSource<<410745236d2fcf88e45ece011723851f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UploadsScreen_DownloadQuery$variables = {
  uploadId: string;
};
export type UploadsScreen_DownloadQuery$data = {
  readonly node: {
    readonly __typename: "File";
    readonly id: string;
    readonly url: string | null | undefined;
  } | {
    readonly __typename: "Image";
    readonly id: string;
    readonly url: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type UploadsScreen_DownloadQuery = {
  response: UploadsScreen_DownloadQuery$data;
  variables: UploadsScreen_DownloadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uploadId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "uploadId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadsScreen_DownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "Image",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "File",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadsScreen_DownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "Image",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "File",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac9569f542854a108a9d5c547c7839f0",
    "id": null,
    "metadata": {},
    "name": "UploadsScreen_DownloadQuery",
    "operationKind": "query",
    "text": "query UploadsScreen_DownloadQuery(\n  $uploadId: ID!\n) {\n  node(id: $uploadId) {\n    __typename\n    ... on Image {\n      id\n      url\n    }\n    ... on File {\n      id\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb71f2b8ffed24900975070cfaa6f0a7";

export default node;
