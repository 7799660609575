import { InputHTMLAttributes, forwardRef } from "react";

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(({ style, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    style={{
      padding: 10,
      fontSize: 16,
      border: "1px solid lightgray",
      cursor: props.type === "submit" ? "pointer" : undefined,
      ...style
    }}
  />
))
