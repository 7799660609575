import { useState } from "react";
import { graphql, useMutation } from "react-relay";
import { LoginScreen_AuthenticateMutation } from "./__generated__/LoginScreen_AuthenticateMutation.graphql";
import { Flex } from "../components/Flex";
import { Form } from "../components/Form";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Text } from "../components/Text";

export const LoginScreen = (props: {
  setSecureToken: (secureToken?: string) => void;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [authenticate, authenticateInFlight] = useMutation<LoginScreen_AuthenticateMutation>(graphql`
    mutation LoginScreen_AuthenticateMutation (
      $email: String!,
      $password: String!
    ) {
      authenticate (input: { email: $email, password: $password }) {
        secureToken
      }
    }
  `)

  return (
    <Flex alignItems="center" justifyContent="center" grow={1} style={{ backgroundColor: "#3F4C48" }}>
      <Flex style={{ width: 600, maxWidth: "calc(100vw - 40px)", gap: 30 }}>
        <img src="/logo.png" alt="norniron" style={{ width: 200, maxWidth: "70vw" }} />
        <Form
          style={{ gap: 10 }}
          onSubmit={(ev) => {
            ev.preventDefault();

            // TODO: better validation
            if (authenticateInFlight) return;
            if (email.length === 0 || password.length === 0) return;

            authenticate({
              variables: { email, password },
              onCompleted: ({ authenticate: { secureToken }}) => {
                props.setSecureToken(secureToken ?? undefined);
                if (secureToken == null) {
                  // TODO: snacks
                  alert("Login failed");
                  setPassword("");
                }
              }
            })
          }}
        >
          <Input type="email" value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder="Email" />
          <Input type="password" minLength={8} value={password} onChange={(ev) => setPassword(ev.target.value)} placeholder="Password" />
          <Text style={{ color: "#FFD6BE" }}>---</Text>
          <Button loading={authenticateInFlight}>Login</Button>
        </Form>
      </Flex>
    </Flex>
  )
}
