import { CSSProperties, HTMLAttributes } from "react";
import { Loader } from "./Loader";

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  backgroundColor?: CSSProperties["backgroundColor"],
  borderColor?: CSSProperties["borderColor"],
  loading?: boolean;
  disabled?: boolean;
}

export const Button = ({ style, backgroundColor, borderColor, loading, children, disabled, ...props }: ButtonProps) => (
  <button
    {...props}
    disabled={disabled}
    style={{
      fontSize: 16,
      padding: 10,
      height: 40,
      lineHeight: 0,
      backgroundColor: backgroundColor ?? '',
      borderColor: borderColor ?? 'lightgray',
      borderWidth: 1,
      borderStyle: 'solid',
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...style
    }}
  >
    {loading ? <Loader style={{ display: "flex" }} /> : children}
  </button>
)
