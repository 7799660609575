import ReactDOM from 'react-dom/client';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { CloudProvider } from './providers/CloudProvider';
import "./styles.css";
import { App } from './App';
import { DialogProvider } from './providers/DialogProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AuthenticationProvider>
    <CloudProvider>
      <DialogProvider>
        <App />
      </DialogProvider>
    </CloudProvider>
  </AuthenticationProvider>
);
