/**
 * @generated SignedSource<<35858d9b3c8dba4e6807661ba7033388>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthenticationProvider_FetchUserQuery$variables = Record<PropertyKey, never>;
export type AuthenticationProvider_FetchUserQuery$data = {
  readonly currentUser: {
    readonly accounts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly clientApplications: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly slug: string;
              };
            }>;
          };
          readonly id: string;
          readonly name: string;
        };
      }>;
    };
    readonly email: string;
    readonly id: string;
  } | null | undefined;
};
export type AuthenticationProvider_FetchUserQuery = {
  response: AuthenticationProvider_FetchUserQuery$data;
  variables: AuthenticationProvider_FetchUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientApplicationConnection",
                    "kind": "LinkedField",
                    "name": "clientApplications",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClientApplicationEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClientApplication",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "slug",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticationProvider_FetchUserQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthenticationProvider_FetchUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "44fa02428f36a67ed7c2d8293add018c",
    "id": null,
    "metadata": {},
    "name": "AuthenticationProvider_FetchUserQuery",
    "operationKind": "query",
    "text": "query AuthenticationProvider_FetchUserQuery {\n  currentUser {\n    id\n    email\n    accounts {\n      edges {\n        node {\n          id\n          name\n          clientApplications {\n            edges {\n              node {\n                id\n                slug\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13115b85b3dd4655c165a11d4acef2f2";

export default node;
