import { Flex } from "../components/Flex";
import { Text } from "../components/Text";
import { useAuthentication } from "../hooks/useAuthentication";

export const AccountsScreen = () => {
  const { currentUser } = useAuthentication();
  const accounts = (currentUser.accounts.edges ?? []).map(a => a.node);

  if (accounts.length === 0) {
    return (
      <Flex grow={1} alignItems='center' justifyContent='center'>
        <Text>You have <strong>no accounts</strong> associated with your user 😢</Text>
      </Flex>
    )
  }

  return (
    <Flex grow={1} gap={20} style={{ padding: 30 }}>
      <Text bold>Your accounts</Text>
      {accounts.map(account => (
        <Flex key={account.id} gap={5} style={{ background: "rgba(0,0,0,0.06)", padding: 30 }}>
          <Text small subdued>Account name</Text>
          <Text>{account.name}</Text>
          <Flex style={{ height: 10 }} />
          <Text small subdued>Accessable apps</Text>
          <Text>{(account.clientApplications.edges ?? []).map(e => e.node).map(a => a.slug).join(", ")}</Text>
        </Flex>
      ))}
    </Flex>
  )
}
