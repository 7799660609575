import { useContext } from "react";
import { AuthContext, VerifiedAuth } from "../providers/AuthenticationProvider";

export function useAuthentication() {
  const auth = useContext(AuthContext);
  if (auth.currentUser == null) {
    throw new Error(
      `Components using useAuthentication must be wrapped in an AuthenticationProvider`,
    );
  }
  return auth as VerifiedAuth;
}
