import { HTMLAttributes, CSSProperties, useRef } from "react";

type FlexProps = HTMLAttributes<HTMLDivElement> & {
  row?: boolean,
  grow?: CSSProperties["flexGrow"],
  shrink?: CSSProperties["flexShrink"],
  alignItems?: CSSProperties["alignItems"],
  justifyContent?: CSSProperties["justifyContent"],
  gap?: CSSProperties["gap"],
}

export const Flex = ({ style, row, grow, shrink, alignItems, justifyContent, gap, ...props }: FlexProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      {...props}
      style={{
        display: "flex",
        flexDirection: row ? "row" : "column",
        flexGrow: grow,
        flexShrink: shrink,
        alignItems,
        justifyContent,
        gap,
        ...style
      }}
    />
  )
};
