import { Suspense, createRef, useEffect, useState } from "react";
import { Flex } from "../components/Flex";
import { Input } from "../components/Input";
import { first } from "lodash";
import { ConnectionHandler, graphql, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { UploadsScreen_UploadInitMutation } from "./__generated__/UploadsScreen_UploadInitMutation.graphql";
import axios from "axios";
import { Text } from "../components/Text";
import { UploadsScreen_UploadCompleteMutation } from "./__generated__/UploadsScreen_UploadCompleteMutation.graphql";
import { Button } from "../components/Button";
import { LoadingScreen } from "./LoadingScreen";
import { UploadsScreen_UploadsQuery } from "./__generated__/UploadsScreen_UploadsQuery.graphql";
import { ID } from "../types";
import { UploadsScreen_DownloadQuery } from "./__generated__/UploadsScreen_DownloadQuery.graphql";
import copyToClipboard from "copy-to-clipboard";
import { UploadsScreen_UploadFragment$key } from "./__generated__/UploadsScreen_UploadFragment.graphql";

type PresignedUrl = UploadsScreen_UploadInitMutation["response"]["uploadInit"]["presignedUrl"];

type UploadState = {
  tag: "idle" | "preparing-upload" | "finalizing"
} | {
  tag: "uploading",
  progress: number,
}

export const UploadsScreen = () => {
  const [state, setState] = useState<UploadState>({ tag: "idle" });
  const inputRef = createRef<HTMLInputElement>();

  const [uploadInit] = useMutation<UploadsScreen_UploadInitMutation>(graphql`
    mutation UploadsScreen_UploadInitMutation (
      $filename: String!
      $connections: [ID!]!
    ) {
      uploadInit(input: { filename: $filename }) {
        upload @prependEdge(connections: $connections) {
          node {
            ...UploadsScreen_UploadFragment
          }
        }
        presignedUrl {
          url
          fields
        }
      }
    }
  `)

  const [uploadComplete] = useMutation<UploadsScreen_UploadCompleteMutation>(graphql`
    mutation UploadsScreen_UploadCompleteMutation (
      $key: String!
    ) {
      uploadComplete ( input: { key: $key } ) {
        upload {
          ...UploadsScreen_UploadFragment
        }
      }
    }
  `)

  const handleUpload = () => {
    try {
      const file = first(inputRef.current?.files);
      if (file == null) return;

      setState({ tag: "preparing-upload" });
      uploadInit({
        variables: {
          filename: file.name,
          connections: [
            ConnectionHandler.getConnectionID("norn-iron:me:account:test", "UploadsScreen_uploads"),
          ]
        },
        onCompleted: (resp) => {
          setState({ tag: "uploading", progress: 0 });
          performUpload(file, resp.uploadInit.presignedUrl);
        },
        onError: (err) => { throw err }
      })
    } catch (err) {
      // TODO: error mutation
      console.error("Upload failed", err);
      setState({ tag: "idle" })
    }
  }

  const performUpload = (file: File, { url, fields }: PresignedUrl) => {
    const formData = new FormData();
    Object.keys(fields).forEach(k => formData.append(k, fields[k]));
    formData.append("file", file);
    formData.append("Content-Type", "image/jpg");

    axios.post(
      url,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (ev) => {
          setState({ tag: "uploading", progress: ev.progress ?? 0 })
        }
      },
    ).then(() => {
      setState({ tag: "finalizing" });
      uploadComplete({
        variables: { key: fields["key"] },
        onCompleted: () => {
          setState({ tag: "idle" });
        },
        onError: (err) => { throw err }
      })
    });
  }

  return (
    <Flex grow={1}>
      <Flex row justifyContent="flex-end" style={{ padding: 20 }}>
        <Button
          disabled={state.tag !== "idle"}
          onClick={() => inputRef.current?.click()}
          children={
            state.tag === "idle" ? "+ Upload a file" : state.tag === "preparing-upload" ? "Preparing upload" : state.tag === "uploading" ? `${Math.round(state.progress * 100)}%` : "Finalizing"
          }
        />
        <Input
          style={{ display: "none" }}
          type="file"
          value=""
          ref={inputRef}
          onChange={handleUpload}
        />
      </Flex>

      <Suspense fallback={<LoadingScreen message="Fetching uploads" />}>
        <UploadsList />
      </Suspense>
    </Flex>
  );
}

const UploadsList = () => {
  const { currentAccount } = useLazyLoadQuery<UploadsScreen_UploadsQuery>(graphql`
    query UploadsScreen_UploadsQuery($first: Int!, $after: String) {
      currentAccount {
        id
        uploads(first: $first, after: $after) @connection(key: "UploadsScreen_uploads") {
          edges {
            node {
              ...UploadsScreen_UploadFragment
            }
          }
        }
      }
    }
  `, {
    first: 20,
    after: undefined
  })

  return (
    <Flex style={{ borderTop: `1px solid lightgray` }}>
      {currentAccount?.uploads.edges.map(({ node }, index) => <UploadRow upload={node} key={index} />)}
    </Flex>
  )
}

const UploadRow = (props: { upload: UploadsScreen_UploadFragment$key }) => {
  const upload = useFragment(graphql`
    fragment UploadsScreen_UploadFragment on Upload {
      __typename
      ...on Image {
        id
        filename
        status
      }
      ...on File {
        id
        filename
        status
      }
    }
  `, props.upload);

  if (upload.__typename !== "File" && upload.__typename !== "Image") {
    throw new Error(`Unhandled upload type: ${JSON.stringify(upload)}`)
  }

  return (
    <Flex key={upload.id} row gap={20} alignItems="center" style={{ padding: 20, borderBottom: `1px solid lightgray` }}>
      <Text>{upload.status === "presigned" ? "🟠" : upload.status === "complete" ? "🟢" : "🔴"}</Text>
      <Text>{upload.filename}</Text>
      <Flex row grow={1} />
      {upload.status === "complete" && (
        <DownloadButtons uploadId={upload.id} />
      )}
    </Flex>
  )
}

const DownloadButtons = (props: { uploadId?: ID }) => {
  const [state, setState] = useState<"idle" | "downloading" | "copying-url">("idle");

  return (
    <Flex row gap={20}>
      <Button
        loading={state === "downloading"}
        onClick={() => setState("downloading")}
      >
        Download
      </Button>

      <Button
        loading={state === "copying-url"}
        onClick={() => setState("copying-url")}
      >
        Copy URL
      </Button>

      {(state === "downloading" || state === "copying-url") && props.uploadId != null && (
        <Suspense>
          <Downloader
            uploadId={props.uploadId}
            onComplete={(url) => {
              if (state === "copying-url") {
                copyToClipboard(url)
              } else {
                window.location.replace(url);
              }

              setState("idle");
            }}
          />
        </Suspense>
      )}
    </Flex>
  )
}

const Downloader = ({ uploadId, onComplete }: { uploadId: ID, onComplete: (url: string) => void }) => {
  const { node } = useLazyLoadQuery<UploadsScreen_DownloadQuery>(graphql`
    query UploadsScreen_DownloadQuery ($uploadId: ID!) {
      node(id: $uploadId){
        __typename
        ...on Image {
          id
          url
        }
        ...on File {
          id
          url
        }
      }
    }
  `, {
    uploadId
  }, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if ((node?.__typename !== "File" && node?.__typename !== "Image") || node.url == null) {
      throw new Error("Upload not found")
    }

    onComplete(node.url);
  }, [node, onComplete])

  return null;
}
