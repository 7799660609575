/**
 * @generated SignedSource<<389f7a0a1e1d29f22b38b0526f17a84a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadsScreen_UploadCompleteMutation$variables = {
  key: string;
};
export type UploadsScreen_UploadCompleteMutation$data = {
  readonly uploadComplete: {
    readonly upload: {
      readonly " $fragmentSpreads": FragmentRefs<"UploadsScreen_UploadFragment">;
    };
  };
};
export type UploadsScreen_UploadCompleteMutation = {
  response: UploadsScreen_UploadCompleteMutation$data;
  variables: UploadsScreen_UploadCompleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "filename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadsScreen_UploadCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadCompletePayload",
        "kind": "LinkedField",
        "name": "uploadComplete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "upload",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UploadsScreen_UploadFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadsScreen_UploadCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadCompletePayload",
        "kind": "LinkedField",
        "name": "uploadComplete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "upload",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isUpload"
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Image",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "File",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73db56a3f0f6d60afad90f677f772517",
    "id": null,
    "metadata": {},
    "name": "UploadsScreen_UploadCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation UploadsScreen_UploadCompleteMutation(\n  $key: String!\n) {\n  uploadComplete(input: {key: $key}) {\n    upload {\n      __typename\n      ...UploadsScreen_UploadFragment\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment UploadsScreen_UploadFragment on Upload {\n  __isUpload: __typename\n  __typename\n  ... on Image {\n    id\n    filename\n    status\n  }\n  ... on File {\n    id\n    filename\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "013d9afbf4614d01a09819eac55dffae";

export default node;
