import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { Json } from "../types";
import config from "../config";
import { random } from "lodash";

type RelayEnvProps = { graphqlUrl: string } & ({ secureToken: undefined } | { secureToken: string, onNotAuthed: () => void })

export const createRelayEnvironment = (args: RelayEnvProps) => {
  const sendRequest = () => (
    async (
      params: { operationKind: string; name: string; text?: string | null },
      variables: Json,
    ) => {
      if (config.isDevelopment) {
        await new Promise(r => setTimeout(r, random(100, 300)));
      }

      const response = await fetch(args.graphqlUrl, {
        method: "POST",
        headers: {
          "Authorization": `Token ${args.secureToken ?? ''}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: params.text,
          variables,
        }),
      });

      if (response.status === 401 && args.secureToken != null) {
        args.onNotAuthed()
        return;
      }

      if (response.status === 401) {
        throw new Error("Access denied");
      }

      const body = await response.text();

      if (!response.ok) {
        throw new Error(body);
      }

      return JSON.parse(body);
    }
  )

  return new Environment({
    network: Network.create(sendRequest()),
    store: new Store(new RecordSource()),
  });
};
