/**
 * @generated SignedSource<<0cf452adbc69c0da37ef634a4b8a0acf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadsScreen_UploadInitMutation$variables = {
  connections: ReadonlyArray<string>;
  filename: string;
};
export type UploadsScreen_UploadInitMutation$data = {
  readonly uploadInit: {
    readonly presignedUrl: {
      readonly fields: any;
      readonly url: string;
    };
    readonly upload: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"UploadsScreen_UploadFragment">;
      };
    };
  };
};
export type UploadsScreen_UploadInitMutation = {
  response: UploadsScreen_UploadInitMutation$data;
  variables: UploadsScreen_UploadInitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filename"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "filename",
        "variableName": "filename"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PresignedUrl",
  "kind": "LinkedField",
  "name": "presignedUrl",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fields",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "filename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadsScreen_UploadInitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UploadInitPayload",
        "kind": "LinkedField",
        "name": "uploadInit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadEdge",
            "kind": "LinkedField",
            "name": "upload",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UploadsScreen_UploadFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadsScreen_UploadInitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UploadInitPayload",
        "kind": "LinkedField",
        "name": "uploadInit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadEdge",
            "kind": "LinkedField",
            "name": "upload",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isUpload"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "Image",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "File",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "upload",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0422e7ccddee32f11cd7eb0d103e6f5c",
    "id": null,
    "metadata": {},
    "name": "UploadsScreen_UploadInitMutation",
    "operationKind": "mutation",
    "text": "mutation UploadsScreen_UploadInitMutation(\n  $filename: String!\n) {\n  uploadInit(input: {filename: $filename}) {\n    upload {\n      node {\n        __typename\n        ...UploadsScreen_UploadFragment\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n    presignedUrl {\n      url\n      fields\n    }\n  }\n}\n\nfragment UploadsScreen_UploadFragment on Upload {\n  __isUpload: __typename\n  __typename\n  ... on Image {\n    id\n    filename\n    status\n  }\n  ... on File {\n    id\n    filename\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9738b8fe1a08f27868ebfd6f5331643";

export default node;
