import { ChildrenProps } from "../types";
import { ReactNode, createContext, useState } from "react";
import { v4 as uuid } from "uuid";
import { Flex } from "../components/Flex";
import { Text } from "../components/Text";
import { Button } from "../components/Button";

type ConfirmProps = {
  title: string;
  body: ReactNode;
  onConfirm: () => void;
}

type DialogContextType = {
  confirm: (props: ConfirmProps) => void;
};

export const DialogContext = createContext<DialogContextType>(null!);

export const DialogProvider = (props: ChildrenProps) => {
  const [dialogs, setDialogs] = useState<{ type: "confirm", id: string, props: ConfirmProps }[]>([])

  return (
    <DialogContext.Provider
      value={{
        confirm: (props) => {
          setDialogs(d => [...d, { type: "confirm", id: uuid(), props }]);
        }
      }}
    >
      {dialogs.map(dialog => {
        if (dialog.type === "confirm") {
          return (
            <ConfirmDialog
              key={dialog.id}
              {...dialog.props}
              onClose={() => {
                setDialogs(d => d.filter(d => d.id !== dialog.id))
              }}
            />
          )
        }
        throw new Error(`Unknown dialog type: ${JSON.stringify(dialog)}`);
      })}
      {props.children}
    </DialogContext.Provider>
  )
}

const ConfirmDialog = ({ title, body, onConfirm, onClose }: ConfirmProps & {
  onClose: () => void;
}) => {
  return (
    <Flex onClick={() => onClose()} alignItems="center" justifyContent="center" style={{ position: "absolute", background: "rgba(0,0,0,0.3)", width: "100vw", height: "100vh" }}>
      <Flex onClick={(ev) => ev.stopPropagation()} style={{ padding: 30, background: "white" }}>
        <Text bold>{title}</Text>
        <Flex style={{ height: 10 }} />
        <Text>{body}</Text>
        <Flex style={{ height: 20 }} />
        <Flex row gap={10}>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button onClick={() => onConfirm()}>Confirm</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
