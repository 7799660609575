import { HTMLAttributes } from "react";

type FormProps = HTMLAttributes<HTMLFormElement>;

export const Form = ({ style, ...props }: FormProps) => (
  <form
    {...props}
    style={{
      display: "flex",
      flexDirection: "column",
      ...style
    }}
  />
)
