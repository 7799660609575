import { Suspense, createContext, useEffect, useMemo, useState } from "react";
import { RelayEnvironmentProvider, graphql, useLazyLoadQuery } from "react-relay";
import { LoadingScreen } from "../screens/LoadingScreen";
import { LoginScreen } from "../screens/LoginScreen";
import { ChildrenProps, CurrentUser } from "../types";
import { createRelayEnvironment } from "../lib/createRelayEnvironment";
import config from "../config";
import { AuthenticationProvider_FetchUserQuery } from "./__generated__/AuthenticationProvider_FetchUserQuery.graphql";

const authenticatingMessage = "Authenticating";
const tokenStorageKey = "secureToken";

type AuthContextType = {
  secureToken?: string;
  currentUser?: CurrentUser;
  logout: () => void;
}

export type VerifiedAuth = Omit<AuthContextType, "currentUser"> & {
  currentUser: CurrentUser;
}

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthenticationProvider = (props: ChildrenProps) => {
  const [secureToken, setSecureToken] = useState(localStorage.getItem(tokenStorageKey) ?? undefined)
  const [currentUser, setCurrentUser] = useState<CurrentUser>();

  const environment = useMemo(() => {
    if (secureToken == null) {
      return createRelayEnvironment({ graphqlUrl: config.cloudGraphqlUrl, secureToken: undefined })
    } else {
      return createRelayEnvironment({
        graphqlUrl: config.cloudGraphqlUrl,
        secureToken,
        onNotAuthed: () => {
          localStorage.clear();
          setSecureToken(undefined);
        }
      })
    }
  }, [secureToken]);

  if (environment == null) {
    return <LoadingScreen message={authenticatingMessage} />
  }

  return (
    <AuthContext.Provider
      value={{
        secureToken,
        currentUser,
        logout: () => {
          localStorage.removeItem(tokenStorageKey);
          window.location.reload();
        }
      }}
    >
      {currentUser != null ? props.children : (
        <RelayEnvironmentProvider environment={environment}>
          <Suspense fallback={<LoadingScreen message={authenticatingMessage} />}>
            <Authenticator
              setCurrentUser={setCurrentUser}
              setSecureToken={(t) => {
                setSecureToken(t);
                if (t == null) {
                  localStorage.removeItem(tokenStorageKey);
                } else {
                  localStorage.setItem(tokenStorageKey, t)
                }
              }}
            />
          </Suspense>
        </RelayEnvironmentProvider>
      )}
    </AuthContext.Provider>
  )
}

const Authenticator = ({
  setCurrentUser,
  setSecureToken,
}: {
  setCurrentUser: (user?: CurrentUser) => void;
  setSecureToken: (token?: string) => void;
}): JSX.Element => {
  const response = useLazyLoadQuery<AuthenticationProvider_FetchUserQuery>(
    graphql`
      query AuthenticationProvider_FetchUserQuery {
        currentUser {
          id
          email
          accounts {
            edges {
              node {
                id
                name
                clientApplications {
                  edges {
                    node {
                      id
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {}
  );

  useEffect(() => {
    setCurrentUser(response.currentUser ?? undefined);
  }, [response, setCurrentUser])

  if (response.currentUser == null) {
    return <LoginScreen setSecureToken={setSecureToken} />;
  }

  return <LoadingScreen message={authenticatingMessage} />
}
